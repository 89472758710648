import { Gutter } from 'antd/lib/grid/row';

const GUTTER: Gutter | [Gutter, Gutter] = [
    {
        xxl: 40,
        xl: 40,
        lg: 40,
        md: 30,
        sm: 20,
        xs: 20
    },
    40
];

export default {
    GUTTER
};
