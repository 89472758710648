import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers/index';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

let middleware = [sagaMiddleware];

if (process.env.NODE_ENV === 'production') {
    middleware = [...middleware];
} else {
    const logger = require('redux-logger').createLogger();
    middleware = [...middleware, logger];
}

const store = createStore(reducers, applyMiddleware(...middleware));

sagaMiddleware.run(sagas);

export default store;
