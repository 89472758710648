import { Col } from 'antd';
import React, { Component } from 'react';
import './index.scss';

type Props = {
    className?: string;
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
    className: ''
};

type State = {};

export default class Card extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return <div className={`card ${this.props.className}`}>{this.props.children}</div>;
    }
}
