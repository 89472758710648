import BackgroundColor from '@component/BackgroundColor';
import Button from '@component/Button';
import Image from '@component/Image';
import Text from '@component/Text';
import Theme from '@constant/theme';
import * as api from '@redux/api';
import util from '@util/util';
import { Col, Form, Input, message, Row, Select } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import phoneUtil from 'google-libphonenumber';
import React, { Component, useContext } from 'react';
import countries from './countries';
import './index.scss';
import { GlobalContext } from 'context/GlobalContext';

type Props = {
    title?: string;
    form: WrappedFormUtils;
    contactEmail?: string;
    address?: string;
    supportTitle?: string;
    supportContent?: string;
    supportEmail?: string;
    nameLabel?: string;
    namePlaceholder?: string;
    emailLabel?: string;
    emailPlaceholder?: string;
    emailErrorMessage?: string;
    companyLabel?: string;
    companyPlaceholder?: string;
    jobTitleLabel?: string;
    jobTitlePlaceholder?: string;
    countryCodePlaceholder?: string;
    phoneLabel?: string;
    countryLabel?: string;
    countryPlaceholder?: string;
    cityLabel?: string;
    cityPlaceholder?: string;
    subjectLabel?: string;
    subjectPlaceholder?: string;
    subjectOption?: string[];
    messageLabel?: string;
    messagePlaceholder?: string;
    sendButtonLabel?: string;
    successMessage?: string;
    warningMessage?: string;
    imageURL: string;
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
    title: 'Contact Us',
    contactEmail: 'NDHK.info@nttdata.com',
    address: "Suites 705-6, 7/F., 1063 King's \nRoad Quarry Bay, Hong Kong",
    supportTitle: 'Support',
    supportContent: 'We’re here to help. Get in touch if you have technical, account or billing enquiries.',
    supportEmail: 'support.hk@nttdata.com',
    nameLabel: 'Name',
    namePlaceholder: 'Name',
    emailLabel: 'Email Address',
    emailPlaceholder: 'tomchan@company.com',
    emailErrorMessage: 'Invalid email address',
    companyLabel: 'Company Name',
    companyPlaceholder: 'Company name',
    jobTitleLabel: 'Job Title',
    jobTitlePlaceholder: 'e.g. Sales Representative, Account Manager',
    countryCodePlaceholder: '+852',
    phoneLabel: 'Phone',
    countryLabel: 'Country',
    countryPlaceholder: 'Select country',
    cityLabel: 'City',
    cityPlaceholder: 'City',
    subjectLabel: 'Subject',
    subjectPlaceholder: 'Choose your inquiry type',
    subjectOption: ['Sales Inquiry', 'Media Inquiry', 'Partner Inquiry', 'Others'],
    messageLabel: 'Message',
    messagePlaceholder: 'Your message...',
    sendButtonLabel: 'Send',
    successMessage: 'Your message has been successfully sent.',
    warningMessage: 'Failed to send your message. Please try later or contact us by other way.',
};

type State = {
    loading: boolean;
    phonePlaceholder: string;
};

class ContactFormComponent extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            phonePlaceholder: '5123 4567',
        };
    }

    _isEmailValid = (rule: any, value: any, callback: any) => {
        if (!value) return callback('');
        if (
            !/^[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(
                value
            )
        ) {
            return callback(this.props.emailErrorMessage);
        }
        callback();
    };

    _onSend = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && !this.state.loading) {
                this.setState({ loading: true });
                api.sendInquiry(values)
                    .then((e) => {
                        if (e.data === 'OK') {
                            message.success(this.props.successMessage);
                            this.props.form.resetFields();
                        } else {
                            message.warn(this.props.warningMessage);
                        }
                        this.setState({ loading: false });
                    })
                    .catch((e) => {
                        message.warn(this.props.warningMessage);
                        this.setState({ loading: false });
                    });
            }
        });
    };

    _getExampleNumber = (name: any) => {
        try {
            let countryCode = countries.filter((e) => e.name == name)[0].code;
            var util = phoneUtil.PhoneNumberUtil.getInstance();
            var numberObj = util.getExampleNumberForType(countryCode, phoneUtil.PhoneNumberType.MOBILE);
            this.setState({
                phonePlaceholder: util.format(numberObj, phoneUtil.PhoneNumberFormat.NATIONAL).replace('1234 5678', '5123 4567'),
            });
        } catch (e) { }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <BackgroundColor className={'form-template'} color={'white'}>
                <Image className={'top-right'} src={`${this.props.imageURL}img/img-curve-topright.png`} />
                <Image className={'bottom-left'} src={`${this.props.imageURL}img/img-curve-bottomleft.png`} />
                <Col>
                    <Text type={'header'} color={'black'}>
                        {this.props.title}
                    </Text>
                    <Row type={'flex'} justify={'start'} gutter={Theme.GUTTER}>
                        <Col className={'left-content'} xxl={11} xl={11} lg={11} md={11} sm={24} xs={24}>
                            <Row type={'flex'}>
                                <Image className={'ic'} src={`${this.props.imageURL}img/ic-email.svg`} />
                                <Button
                                    type={'link'}
                                    onClick={() =>
                                        util.onClick(
                                            {
                                                label: 'click-contact-email',
                                                actionType: 'redirect',
                                                url: `mailto:${this.props.contactEmail}`,
                                            },
                                            () => (window.location.href = `mailto:${this.props.contactEmail}`)
                                        )
                                    }
                                >
                                    {this.props.contactEmail}
                                </Button>
                            </Row>
                            <Row type={'flex'}>
                                <Image className={'ic'} src={`${this.props.imageURL}img/ic-address.svg`} />
                                <Button
                                    type={'link'}
                                    onClick={() =>
                                        util.onClick(
                                            {
                                                label: 'open-map',
                                                actionType: 'function',
                                            },
                                            () => window.open(`https://maps.google.com/?q=${this.props.address}`, '_blank')
                                        )
                                    }
                                >
                                    {this.props.address}
                                </Button>
                            </Row>
                            <Text type={'title'} color={'black'}>
                                {this.props.supportTitle}
                            </Text>
                            <Text>{this.props.supportContent}</Text>
                            <Row type={'flex'}>
                                <Image className={'ic'} src={`${this.props.imageURL}img/ic-email.svg`} />
                                <Button
                                    type={'link'}
                                    onClick={() =>
                                        util.onClick(
                                            {
                                                label: 'click-support-email',
                                                actionType: 'function',
                                            },
                                            () => (window.location.href = `mailto:${this.props.supportEmail}?Subject=Support%20Inquiry`)
                                        )
                                    }
                                >
                                    {this.props.supportEmail}
                                </Button>
                            </Row>
                        </Col>
                        <Col xxl={1} xl={1} lg={1} md={1} sm={0} xs={0} />
                        <Col className={'right-content'} xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Form layout={'vertical'} hideRequiredMark={true}>
                                <Form.Item label={this.props.nameLabel} help={''}>
                                    {getFieldDecorator('name', {
                                        rules: [{ required: true, whitespace: true }],
                                    })(<Input size={'large'} placeholder={this.props.namePlaceholder} maxLength={100} />)}
                                </Form.Item>
                                <Form.Item label={this.props.emailLabel} required>
                                    {getFieldDecorator('email', {
                                        rules: [{ validator: this._isEmailValid }],
                                    })(<Input size={'large'} type={'email'} placeholder={this.props.emailPlaceholder} />)}
                                </Form.Item>
                                <Form.Item label={this.props.companyLabel} help={''}>
                                    {getFieldDecorator('company', {
                                        rules: [{ required: true, whitespace: true }],
                                    })(<Input size={'large'} placeholder={this.props.companyPlaceholder} maxLength={100} />)}
                                </Form.Item>
                                <Form.Item label={this.props.jobTitleLabel}>
                                    {getFieldDecorator('jobTitle')(
                                        <Input size={'large'} placeholder={this.props.jobTitlePlaceholder} maxLength={100} />
                                    )}
                                </Form.Item>
                                <Form.Item label={this.props.phoneLabel}>
                                    {getFieldDecorator('phone', {
                                        rules: [{ required: false }],
                                    })(
                                        <Input
                                            size={'large'}
                                            type={'tel'}
                                            addonBefore={getFieldDecorator('countryCode', {
                                                initialValue: this.props.countryCodePlaceholder,
                                            })(
                                                <Select
                                                    size={'large'}
                                                    dropdownMatchSelectWidth={false}
                                                    style={{ width: 100 }}
                                                    optionLabelProp={'label'}
                                                    onSelect={(e) => this._getExampleNumber(e)}
                                                >
                                                    {countries.map((e, i: number) => (
                                                        <Select.Option key={i} value={e.name} label={e.dial_code}>
                                                            {`${e.name} (${e.dial_code})`}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            )}
                                            style={{ width: '100%' }}
                                            placeholder={this.state.phonePlaceholder}
                                            maxLength={20}
                                        />
                                    )}
                                </Form.Item>
                                <Row type={'flex'} gutter={32}>
                                    <Col span={12}>
                                        <Form.Item label={this.props.countryLabel}>
                                            {getFieldDecorator('country')(
                                                <Select size={'large'} placeholder={this.props.countryPlaceholder}>
                                                    {countries.map((e, i: number) => (
                                                        <Select.Option key={i} value={e.name}>
                                                            {e.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={this.props.cityLabel}>
                                            {getFieldDecorator('city')(
                                                <Input size={'large'} placeholder={this.props.cityPlaceholder} maxLength={100} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item label={this.props.subjectLabel} help={''}>
                                    {getFieldDecorator('subject', {
                                        rules: [{ required: true, whitespace: true }],
                                    })(
                                        <Select size={'large'} placeholder={this.props.subjectPlaceholder}>
                                            {this.props.subjectOption?.map((e: string, i: number) => (
                                                <Select.Option key={i} value={e}>
                                                    {e}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label={this.props.messageLabel} help={''}>
                                    {getFieldDecorator('message', {
                                        rules: [{ required: true, whitespace: true }],
                                    })(<TextArea placeholder={this.props.messagePlaceholder} />)}
                                </Form.Item>
                            </Form>
                            <Row type={'flex'} justify={'center'}>
                                <Button
                                    type={'solid'}
                                    loading={this.state.loading}
                                    onClick={() =>
                                        util.onClick(
                                            {
                                                label: 'send-inquiry',
                                                actionType: 'redirect',
                                                url: '',
                                            },
                                            this._onSend
                                        )
                                    }
                                    data={{
                                        id: 'contactformsend',
                                        label: 'send',
                                        actionType: 'function',
                                    }}
                                >
                                    {this.props.sendButtonLabel}
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </BackgroundColor>
        );
    }
}

function ContactForm(props: any) {
    const globalContext = useContext(GlobalContext);
    return (
        <ContactFormComponent {...{ ...props, ...globalContext }}></ContactFormComponent >
    )
}

export default Form.create<any>()(ContactForm);