import Text from '@component/Text';
import { ParagraphData } from '@model/site';
import React, { Component } from 'react';
import './index.scss';

type Props = {
    data?: ParagraphData;
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
    data: {
        title: 'Item Name',
        content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam.'
    }
};

type State = {};

export default class Paragraph extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className={'paragraph-component'}>
                <Text type={'title'} color={'black'}>
                    {this.props.data?.title}
                </Text>
                <div className={'border'}></div>
                <Text>{this.props.data?.content}</Text>
            </div>
        );
    }
}
