import BackgroundColor from '@component/BackgroundColor';
import BackgroundImage from '@component/BackgroundImage';
import Button from '@component/Button';
import Image from '@component/Image';
import Text from '@component/Text';
import VideoComponent from '@component/VideoComponent';
import Theme from '@constant/theme';
import { ButtonData } from '@model/site';
import { Col, Modal, Row } from 'antd';
import React, { Component, useContext } from 'react';
import './index.scss';
import { GlobalContext } from 'context/GlobalContext';

type Props = {
    backgroundColor?: 'grey' | 'white';
    background?: string;
    backgroundPath?: string;
    position?: 'left' | 'right' | 'full';
    videoId?: string;
    title?: string;
    content?: string;
    buttonData?: ButtonData;
    imageURL: string;
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
    type: 'button',
    backgroundPath: 'img/img-video-preview.png',
    position: 'left',
    videoId: 'juN3u_Nb9ZQ',
    title: '“Integer ut ex vitae enim semper”',
    content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam.'
};

type State = {
    videoVisible: boolean;
};

class VideoComp extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {
            videoVisible: false
        };
    }

    _showVideo = () => {
        this.setState({
            videoVisible: true
        });
    };

    _closeVideo = (e: any) => {
        this.setState({
            videoVisible: false
        });
    };

    render() {
        if (this.props.position == 'full') {
            return (
                <BackgroundImage
                    className={`video-template position-${this.props.position}`}
                    overlay={'rgba(103, 133, 193, 0.4)'}
                    src={`${this.props.imageURL}${this.props.backgroundPath}`}
                >
                    <Row className={'container-full'} type={'flex'} align={'bottom'}>
                        <Image className={'play-button'} src={`${this.props.imageURL}img/ic-play.svg`} onClick={() => this._showVideo()} />
                        <Col className={'video-overlay'} xxl={18} xl={18} lg={18} md={18} sm={24} xs={24}>
                            <Text type={'header'} color={'white'}>
                                {this.props.title}
                            </Text>
                            <Text color={'white'}>{this.props.content}</Text>
                        </Col>
                    </Row>
                    <Modal
                        visible={this.state.videoVisible}
                        onCancel={e => this._closeVideo(e)}
                        centered
                        maskStyle={{ backgroundColor: 'rgba(68, 74, 85, 0.8)' }}
                        width={'100%'}
                        footer={null}
                        destroyOnClose={true}
                        wrapClassName={'video-popup'}
                    >
                        <VideoComponent className={`position-${this.props.position}`} videoId={this.props.videoId} autoplay={1} />
                    </Modal>
                </BackgroundImage>
            );
        }
        let videoBody: JSX.Element[] = [
            <Col key={0} xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                <VideoComponent className={`position-${this.props.position}`} videoId={this.props.videoId} />
            </Col>,
            <Col key={1} className={'content'} xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                <Text type={'header'} color={'black'}>
                    {this.props.title}
                </Text>
                <Text>{this.props.content}</Text>
                <Button type={'outline'} data={this.props.buttonData}>
                    {this.props.buttonData?.label}
                </Button>
            </Col>
        ];

        if (this.props.position == 'right') {
            videoBody = videoBody.reverse();
        }

        return (
            <BackgroundColor className={'video-template'} color={this.props.backgroundColor}>
                <Row type={'flex'} align={'middle'} gutter={Theme.GUTTER} justify={'space-between'}>
                    {videoBody}
                </Row>
            </BackgroundColor>
        );
    }
}

export default function Video(props: any) {
    const globalContext = useContext(GlobalContext);
    return (
        <VideoComp {...{ ...props, ...globalContext }}></VideoComp >
    )
}