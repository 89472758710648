import { SocialEntity } from '@model/site';
import { Button } from 'antd';
import React, { Component } from 'react';
import './index.scss';
import Image from '@component/Image';

type Props = {
    data: SocialEntity;
    imageURL: string;
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {};

type State = {};

export default class SocialButton extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Button shape={'circle'} className={'social-button'} onClick={() => window.open(this.props.data.absoluteUrl)}>
                <Image src={`${this.props.imageURL}img/ic-social-${this.props.data.media}.svg`} onClick={() => {}} />
            </Button>
        );
    }
}
