import { Page, Site } from '@model/site';
import ErrorPage from '@page/ErrorPage';
import PageComponent from '@page/PageComponent';
import { initializeRequest, loadPageRequest } from '@redux/actions';
import history from '@util/history';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import './App.scss';

let trackingId = 'testing-tracking-id';
if (window.location.origin === 'https://magicuat.ndhkcoupon.com') {
    trackingId = 'UA-158513313-4';
}
if (window.location.origin === 'https://magicprod.ndhkcoupon.com' || window.location.origin === 'https://hk.nttdata.com') {
    trackingId = 'UA-158513313-1';
}
// console.log('TackingID', trackingId);
ReactGA.initialize(trackingId);

type Props = {
    initialize: (url: string) => void;
    loadPage: (url: string) => void;
    site: Site;
    page: Page;
    errorCode?: 404 | 500;
};

function mapStateToProps(state: any, ownProps: {}) {
    return {
        site: state.siteReducer.site,
        page: state.siteReducer.page,
        errorCode: state.siteReducer.errorCode,
        location: state.location
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        initialize: (url: string) => dispatch(initializeRequest(url)),
        loadPage: (url: string) => dispatch(loadPageRequest(url))
    };
}

class App extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    _isValidPath(url: string) {
        return !['/404', '/500'].includes(url);
    }

    componentDidMount() {
        ReactGA.set({ page: window.location.pathname });
        ReactGA.pageview(window.location.pathname);
        if (this._isValidPath(window.location.pathname)) {
            this.props.initialize(window.location.pathname.replace(/\//g, ''));
        }
        history.listen((location, action) => {
            ReactGA.set({ page: location.pathname });
            ReactGA.pageview(location.pathname);
            if (this._isValidPath(window.location.pathname)) {
                this.props.loadPage(location.pathname.replace(/\//g, ''));
            }
        });
    }

    render() {
        const { site, page, errorCode } = this.props;
        return (
            <Router>
                <Switch>
                    <Route path="/404">
                        <ErrorPage errorCode={404} />
                    </Route>
                    <Route path="/500">
                        <ErrorPage errorCode={500} />
                    </Route>
                    <Route path="*">
                        <ErrorBoundary>
                            <PageComponent site={site} page={page} errorCode={errorCode} />
                        </ErrorBoundary>
                    </Route>
                </Switch>
            </Router>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

class ErrorBoundary extends React.Component<{}, { error?: Error }> {
    constructor(props: {}) {
        super(props);
        this.state = { error: undefined };
    }

    componentDidCatch(error: Error) {
        this.setState({ error });
        return false;
    }

    render() {
        if (this.state.error) {
            return <ErrorPage errorCode={500} />;
        }
        return this.props.children;
    }
}
