import Button from '@component/Button';
import Image from '@component/Image';
import Text from '@component/Text';
import { ColumnData } from '@model/site';
import { Col, Row } from 'antd';
import React, { Component } from 'react';
import './index.scss';

type Props = {
    className?: string;
    bodyClassName?: string;
    type?: 'text' | 'image';
    align?: 'center' | 'left';
    imageClassName?: string;
    showBorder?: boolean;
    buttonClassName?: string;
    data?: ColumnData;
    imageURL: string,
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
    className: '',
    type: 'text',
    align: 'center',
    showBorder: true,
    buttonClassName: '',
    data: {
        image: 'https://i.ytimg.com/vi/RKbBgob_X40/maxresdefault.jpg',
        imagePath: 'https://i.ytimg.com/vi/RKbBgob_X40/maxresdefault.jpg',
        title: 'Column Name',
        content:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. . Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam.'
    }
};

type State = {};

export default class Column extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Row>
                <Col className={`column ${this.props.className}`}>
                    {this.props.type == 'image' && (
                        <Row type={'flex'} justify={this.props.align == 'center' ? 'center' : undefined}>
                            <Image className={`${this.props.imageClassName ?? 'column-image'}`} src={`${this.props.imageURL}${this.props.data?.imagePath}`} />
                        </Row>
                    )}
                    <Col className={`${this.props.bodyClassName ?? 'column-body'}`}>
                        <Text
                            className={'column-title'}
                            type={'title'}
                            color={'black'}
                            align={this.props.align == 'center' ? 'center' : undefined}
                        >
                            {this.props.data?.title}
                        </Text>
                        {this.props.type == 'text' && this.props.showBorder && (
                            <Row type={'flex'} justify={this.props.align == 'center' ? 'center' : undefined}>
                                <div className={'border'} />
                            </Row>
                        )}
                        <Text className={'column-content'} align={this.props.align == 'center' ? 'center' : undefined}>
                            {this.props.data?.content}
                        </Text>
                        {this.props.data?.buttonData && (
                            <Row type={'flex'} justify={this.props.align == 'center' ? 'center' : undefined}>
                                <Button
                                    className={`column-button ${this.props.buttonClassName}`}
                                    type={'text'}
                                    size={'small'}
                                    data={this.props.data?.buttonData}
                                >
                                    {this.props.data?.buttonData.label}
                                </Button>
                            </Row>
                        )}
                    </Col>
                </Col>
            </Row>
        );
    }
}
