import Paragraph from '@component-group/Paragraph';
import BackgroundColor from '@component/BackgroundColor';
import Button from '@component/Button';
import Text from '@component/Text';
import { ButtonData, ParagraphData } from '@model/site';
import { Col, Row } from 'antd';
import React, { Component } from 'react';
import './index.scss';

type Props = {
    type?: 'paragraph' | 'plain' | 'link' | 'placeholder';
    backgroundColor?: 'grey' | 'white';
    title?: string;
    content?: string;
    paragraphData?: ParagraphData[];
    buttonData?: ButtonData[];
    otherLanguages?: string[];
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
    type: 'paragraph',
    otherLanguages: []
};

type State = {};

export default class TextGroup extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <BackgroundColor className={'text-group-template'} color={this.props.backgroundColor}>
                <Row type={'flex'} justify={'start'} align={'middle'}>
                    <Col className={'text-group-content'} span={24}>
                        <Text
                            type={this.props.type == 'link' ? 'title' : 'header'}
                            color={'black'}
                            align={this.props.type == 'link' ? undefined : 'center'}
                        >
                            {this.props.title}
                        </Text>
                        {this.props.type == 'paragraph' && this.props.paragraphData && (
                            <div className={'type-paragraph'}>
                                {this.props.paragraphData.map((e: ParagraphData, i: number) => (
                                    <Paragraph key={i} data={e} />
                                ))}
                            </div>
                        )}
                        {this.props.type == 'plain' && <Text className={'type-plain'}>{this.props.content}</Text>}
                        {this.props.type == 'placeholder' && (
                            <Text className={'type-placeholder'} align={'center'}>
                                {this.props.content}
                            </Text>
                        )}
                        {this.props.type == 'link' && (
                            <Col className={'type-link'}>
                                {this.props.buttonData?.map((e: ButtonData, i: number) => (
                                    <Row key={i}>
                                        <Button type={'link'} data={e}>
                                            {e.label}
                                        </Button>
                                    </Row>
                                ))}
                            </Col>
                        )}
                        {this.props.otherLanguages && this.props.otherLanguages?.length > 0 && (
                            <div style={{ marginTop: '100px' }}>
                                <Text type={'body'}>Other Languages</Text>
                                <br></br>
                                {this.props.otherLanguages.map((lang: any) => {
                                    return (
                                        <p>
                                            <Button type="link" data={{
                                                id: '',
                                                label: 'Hello',
                                                actionType: 'redirect',
                                                url: lang.url
                                            }}>{lang.title}</Button>
                                        </p>
                                    )
                                })}
                            </div>
                        )}
                    </Col>
                </Row>
            </BackgroundColor>
        );
    }
}
