import BackgroundColor from '@component/BackgroundColor';
import Button from '@component/Button';
import Image from '@component/Image';
import Text from '@component/Text';
import { ButtonData, Page, PageGroup } from '@model/site';
import history from '@util/history';
import util from '@util/util';
import { Col, Dropdown, Menu, Row } from 'antd';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import React, { Component, useContext } from 'react';
import { slide as BurgerMenu } from 'react-burger-menu';
import HamburgerMenu from 'react-hamburger-menu';
import './index.scss';
import { GlobalContext } from 'context/GlobalContext';

type Props = {
    headerLogo?: string;
    countryUrl: string;
    pageGroups?: PageGroup[];
    navigationButton?: ButtonData[];
    region?: string;
    langauge?: string;
    imageURL: string;
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
    headerLogo: 'img/newlogo-ntt.svg',
    region: 'Hong Kong',
    langauge: 'English',
};

type State = {
    open: boolean;
    openKey: string[];
    selectKey: string[];
};

class NavigationBarComponent extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {
            open: false,
            openKey: [],
            selectKey: [],
        };
    }

    componentDidMount() {
        const languageBar = document.getElementById('language-bar');
        const navigationBar = document.getElementById('navigation-bar');
        const nttLogo = document.getElementById('ntt-logo');
        const menu = document.getElementById('navigation-menu');
        if (menu) menu.style.height = 'calc(100vh - 80px)';
        window.onscroll = function () {
            scrollFunction();
        };

        function scrollFunction() {
            if (document.body.scrollTop > 60 || document.documentElement.scrollTop > 60) {
                if (languageBar) languageBar.style.height = '0';
                if (navigationBar) navigationBar.style.height = '60px';
                if (nttLogo) nttLogo.style.height = '36px';
                if (nttLogo) nttLogo.style.width = '105px';
                if (menu) menu.style.height = 'calc(100vh - 60px)';
            } else {
                if (languageBar) languageBar.style.height = '40px';
                if (navigationBar) navigationBar.style.height = '80px';
                if (nttLogo) nttLogo.style.height = '48px';
                if (nttLogo) nttLogo.style.width = '140px';
                if (menu) menu.style.height = 'calc(100vh - 80px)';
            }
        }
    }

    _onOpenChange(openKey: string[]) {
        const latestOpenKey = openKey.find((key) => this.state.openKey.indexOf(key) === -1);
        this.setState({
            openKey: latestOpenKey ? [latestOpenKey] : [],
        });
    }

    _onSelectChange(selectKey: string[]) {
        this.setState({
            selectKey: selectKey,
        });
    }

    _onClick(url?: string) {
        this.setState({ open: false, selectKey: [] });
        if (url && window.location.pathname != `/${url}`) {
            history.push(url);
            enableBodyScroll(document.body);
        }
    }

    _getMenuItem(pages: Page[]) {
        return pages.map((e: Page, i: number) => (
            <Menu.Item key={i} onClick={() => this._onClick(e.url)}>
                {e.title}
            </Menu.Item>
        ));
    }

    _drawerButtonClick() {
        let open = !this.state.open;
        this.setState({ open: open });
        const menu = document.querySelector('.navigation-menu');
        if (menu) {
            if (open) {
                disableBodyScroll(menu);
            } else {
                enableBodyScroll(menu);
            }
        }
    }

    _overlayClick(isOpen: boolean) {
        this.setState({ open: isOpen });
        const menu = document.querySelector('.navigation-menu');
        if (menu) {
            if (isOpen) {
                disableBodyScroll(menu);
            } else {
                enableBodyScroll(menu);
            }
        }
    }

    render() {
        return (
            <Col className={'navigation-bar-component'}>
                <BackgroundColor className={'no-padding border'} color={'white'}>
                    <Row id={'language-bar'} className={'language-bar'} align={'middle'} justify={'space-between'} type={'flex'}>
                        <Row
                            className={'route'}
                            onClick={() =>
                                util.onClick({
                                    label: 'country-select',
                                    actionType: 'redirect',
                                    url: this.props.countryUrl,
                                })
                            }
                        >
                            <span className={'normal'}>Global / </span>
                            {this.props.region}
                        </Row>
                        {/* <Button type={'link'} size={'small'}>
                            {this.props.langauge}
                        </Button> */}
                    </Row>
                </BackgroundColor>
                <BackgroundColor className={'no-padding'} color={'white'}>
                    <Row id={'navigation-bar'} className={'navigation-bar'} align={'middle'} justify={'space-between'} type={'flex'}>
                        <div onClick={() => this._onClick('/')}>
                            <Image id={'ntt-logo'} className={'ntt-logo'} src={`${this.props.imageURL}${this.props.headerLogo}`} onClick={() => this._onClick('')} />
                        </div>
                        <Row className={'navigation-bar-menu'} align={'middle'} type={'flex'}>
                            {this.props.pageGroups
                                ?.filter((e) => e.showInHeader)
                                ?.filter((e) => e.pages && e.pages.length > 0)
                                .map((e: PageGroup, i: number) =>
                                    e.pages.length == 1 ? (
                                        <div key={i} className={'dropdown-btn'} onClick={() => this._onClick(e.pages[0].url)}>
                                            {e.pageGroup}
                                        </div>
                                    ) : (
                                            <Dropdown key={i} overlay={<Menu>{this._getMenuItem(e.pages)}</Menu>}>
                                                <div className={'dropdown-btn'}>{e.pageGroup}</div>
                                            </Dropdown>
                                        )
                                )}
                            {this.props.navigationButton?.map((e: ButtonData) => (
                                <Button key={e.id} type={'outline'} size={'small'} data={e}>
                                    {e.label}
                                </Button>
                            ))}
                        </Row>
                        <Row className={'navigation-drawer-button'}>
                            <HamburgerMenu
                                isOpen={this.state.open}
                                menuClicked={() => this._drawerButtonClick()}
                                width={30}
                                height={15}
                                strokeWidth={3}
                                color="#818998"
                                borderRadius={2}
                                animationDuration={0.2}
                            />
                        </Row>
                    </Row>
                </BackgroundColor>
                <BurgerMenu
                    id={'navigation-menu'}
                    right
                    isOpen={this.state.open}
                    menuClassName={'navigation-menu'}
                    width={'unset'}
                    onStateChange={(e) => this._overlayClick(e.isOpen)}
                    overlayClassName={'navigation-menu-overlay'}
                >
                    <Menu
                        mode={'inline'}
                        inlineIndent={32}
                        openKeys={this.state.openKey}
                        onOpenChange={(e) => this._onOpenChange(e)}
                        onSelect={(e) => this._onSelectChange(e.selectedKeys)}
                        selectedKeys={this.state.selectKey}
                    >
                        {this.props.pageGroups
                            ?.filter((pageGroup) => pageGroup.showInHeader)
                            ?.filter((e) => e.pages && e.pages.length > 0)
                            .map((e: PageGroup) =>
                                e.pages.length == 1 ? (
                                    <Menu.Item key={e.pageGroup} onClick={() => this._onClick(e.pages[0].url)}>
                                        <Text type={'caption'} color={'black'}>
                                            {e.pageGroup}
                                        </Text>
                                    </Menu.Item>
                                ) : (
                                        <Menu.SubMenu
                                            key={e.pageGroup}
                                            title={
                                                <Text type={'caption'} color={'black'}>
                                                    {e.pageGroup}
                                                </Text>
                                            }
                                        >
                                            {e.pages.map((e: Page) => (
                                                <Menu.Item key={e.id} onClick={() => this._onClick(e.url)}>
                                                    <Text type={'caption'}>{e.title}</Text>
                                                </Menu.Item>
                                            ))}
                                        </Menu.SubMenu>
                                    )
                            )}
                    </Menu>
                    <Menu mode={'inline'} selectable={false}>
                        {this.props.navigationButton?.map((e: ButtonData) => (
                            <Menu.Item key={e.url} className={'button-row'}>
                                <Row type={'flex'} align={'middle'} justify={'center'}>
                                    <Button type={'outline'} size={'small'} data={e} onClick={() => this._onClick(e.url)}>
                                        {e.label}
                                    </Button>
                                </Row>
                            </Menu.Item>
                        ))}
                    </Menu>
                    <Menu mode={'inline'} className={'language'} selectable={false}>
                        <Menu.Item>
                            <Row
                                align={'middle'}
                                type={'flex'}
                                onClick={() =>
                                    util.onClick({
                                        label: 'country-select',
                                        actionType: 'redirect',
                                        url: this.props.countryUrl,
                                    })
                                }
                            >
                                <Col className={'route'}>
                                    <span className={'normal'}>Global / </span>
                                    {this.props.region}
                                </Col>
                            </Row>
                        </Menu.Item>
                        {/* <Menu.Item>
                            <Row type={'flex'} align={'middle'} justify={'center'}>
                                <Text type={'caption'}>{this.props.langauge}</Text>
                            </Row>
                        </Menu.Item> */}
                    </Menu>
                </BurgerMenu>
            </Col>
        );
    }
}

export default function NavigationBar(props: any) {
    const globalContext = useContext(GlobalContext);
    return (
        <NavigationBarComponent {...{ ...props, ...globalContext }}></NavigationBarComponent >
    )
}