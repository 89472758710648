import { ParagraphData, Post } from '@model/site';
import Button from '@component/Button';
import { Row, Spin } from 'antd';
import React, { Component, useContext } from 'react';
import './index.scss';
import BackgroundColor from '@component/BackgroundColor';
import Column from '@component-group/Column';
import { getPosts } from '../../../src/redux/api'
import { GlobalContext } from 'context/GlobalContext';

type Props = {
    id: number
    type?: 'paragraph' | 'plain' | 'link' | 'placeholder';
    backgroundColor?: 'grey' | 'white';
    title?: string;
    content?: string;
    paragraphData?: ParagraphData[];
    tags?: string[];
    imageURL: string;
}

const defaultProps = {
    type: 'paragraph',
    selectedTag: '',
};

type State = {
    posts?: Post[];
    selectedTag: string,
};

class PostsComponent extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedTag: ''
        };
    }

    componentDidMount() {
        getPosts(this.props.id).then((response) => {
            this.setState({ posts: response.data })
        })

        if (this.props.tags && this.props.tags?.length > 0) {
            this.setState({ selectedTag: this.props.tags[0] })
        }
    }

    getPosts = (posts: any[]) => {
        const result: Post[] = []
        const selectedTag: string = this.state.selectedTag
        posts.forEach((post: Post) => {
            if (post.tags && post.tags.includes(selectedTag)) {
                result.push(post)
            }
        });
        return result
    }

    render() {
        return (
            <BackgroundColor className={'logo-wall-template'} color={this.props.backgroundColor}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {this.props.tags?.map((tag: string, key: number) => {
                        return (
                            <Button
                                key={key}
                                className={`column-button tag-spacing`}
                                type={this.state.selectedTag === tag ? 'underline' : 'tag'}
                                size={'normal'}
                                data={{
                                    id: 'string',
                                    label: '',
                                    actionType: 'redirect',
                                    url: 'newsroom'
                                }}
                                onClick={() => {
                                    this.setState({ selectedTag: tag })
                                }}
                            >
                                {tag}
                            </Button>
                        )
                    })}
                </div>

                {
                    this.state.posts && this.state.posts.length > 0 ?
                        <Row type={'flex'} justify={'center'} align={'middle'}>
                            <div style={{ width: '100%', maxWidth: '760px' }}>
                                {this.getPosts(this.state.posts || []).map((post: Post, key: number) => {
                                    return (
                                        <Column
                                            key={key}
                                            type={'text'}
                                            align={'left'}
                                            bodyClassName={'info-card-body'}
                                            showBorder={false}
                                            imageURL={this.props.imageURL}
                                            data={{
                                                image: '',
                                                imagePath: '',
                                                title: post.title || '',
                                                content: post.content || '',
                                                buttonData: {
                                                    id: '',
                                                    label: 'Read more',
                                                    actionType: 'redirect',
                                                    url: post.url
                                                }
                                            }}
                                        />
                                    )
                                })}
                            </div>
                        </Row>
                        :
                        <Loading></Loading>
                }
            </BackgroundColor>
        );
    }
}

function Loading() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Spin></Spin>
        </div>
    )
}

export default function Posts(props: any) {
    const globalContext = useContext(GlobalContext);
    return (
        <PostsComponent {...{ ...props, ...globalContext }}></PostsComponent >
    )
}