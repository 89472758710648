import InfoCard from '@component-group/InfoCard';
import BackgroundColor from '@component/BackgroundColor';
import Text from '@component/Text';
import Theme from '@constant/theme';
import { ColumnData } from '@model/site';
import { Col, Row } from 'antd';
import React, { Component, useContext } from 'react';
import './index.scss';
import { GlobalContext } from 'context/GlobalContext';

type Props = {
    backgroundColor?: 'grey' | 'white';
    title?: string;
    cardType?: 'image' | 'icon';
    cardData?: ColumnData[];
    imageURL: string;
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
    title: 'Add an eye-catching sentence here to highlight your service or products',
    cardType: 'image'
};

type State = {};

class CardGroupComponent extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        let span: number = this.props.cardData?.length == 2 ? 12 : 8;
        return (
            <BackgroundColor className={'card-group-template'} color={this.props.backgroundColor}>
                <Row type={'flex'} justify={'center'}>
                    <Text type={'subhead'} color={'black'} align={'center'}>
                        {this.props.title}
                    </Text>
                </Row>
                <Row className={'content-row'} type={'flex'} justify={'space-around'} align={'top'} gutter={Theme.GUTTER}>
                    {this.props.cardData?.map((card: ColumnData, i: number) => (
                        <Col key={i} xxl={span} xl={span} lg={span} md={24} sm={24} xs={24}>
                            <InfoCard type={this.props.cardType} columnData={card} imageURL={this.props.imageURL} />
                        </Col>
                    ))}
                </Row>
            </BackgroundColor>
        );
    }
}

export default function CardGroup(props: any) {
    const globalContext = useContext(GlobalContext);
    return (
        <CardGroupComponent {...{ ...props, ...globalContext }}></CardGroupComponent >
    )
}