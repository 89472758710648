import Column from '@component-group/Column';
import Card from '@component/Card';
import { ColumnData } from '@model/site';
import { Row } from 'antd';
import React, { Component } from 'react';
import './index.scss';

type Props = {
    className?: string;
    type?: 'image' | 'icon';
    columnData?: ColumnData;
    imageURL: string;
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
    className: '',
    type: 'image'
};

type State = {};

export default class InfoCard extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Card className={`info-card-component ${this.props.className}`}>
                <Row>
                    <Column
                        type={'image'}
                        align={this.props.type == 'icon' ? 'center' : 'left'}
                        bodyClassName={'info-card-body'}
                        imageClassName={this.props.type == 'image' ? 'info-card-image-normal' : 'info-card-image-icon'}
                        data={this.props.columnData}
                        imageURL={this.props.imageURL}
                    />
                </Row>
            </Card>
        );
    }
}
