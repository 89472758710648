import BackgroundColor from '@component/BackgroundColor';
import Button from '@component/Button';
import Image from '@component/Image';
import SocialButton from '@component/SocialButton';
import Text from '@component/Text';
import Theme from '@constant/theme';
import { Page, PageGroup, SocialEntity } from '@model/site';
import { Col, Row } from 'antd';
import React, { Component, useContext } from 'react';
import history from '@util/history';
import './index.scss';
import { GlobalContext } from 'context/GlobalContext';

type Props = {
    logo?: string;
    copyright?: string;
    social?: SocialEntity[];
    pageGroups?: PageGroup[];
    imageURL: string;
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
    logo: 'img/newlogo-ntt.svg',
    copyright: ''
};

type State = {};

class FooterComponent extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <BackgroundColor className={'footer-component'} color={'grey'}>
                <Row className={'content-section'} align={'top'} type={'flex'} gutter={Theme.GUTTER}>
                    <Col xxl={8} xl={8} lg={24} md={24} sm={24} xs={24}>
                        <div onClick={() => { history.push('/') }}>
                            <Image className={'ntt-logo'} src={`${this.props.imageURL}${this.props.logo}`} />
                        </div>
                        <Row className={'social-button-row'}>
                            {this.props.social?.map((e: SocialEntity, i: number) => (
                                <SocialButton key={i} data={e} imageURL={this.props.imageURL} />
                            ))}
                        </Row>
                    </Col>
                    <Col xxl={16} xl={16} lg={24} md={24} sm={24} xs={24}>
                        <Row align={'top'} justify={'start'} type={'flex'} gutter={Theme.GUTTER}>
                            {this.props.pageGroups
                                ?.filter(e => e.pages && e.pages.length > 0)
                                .map((e: PageGroup, i: number) => (
                                    <Col key={i} xxl={6} xl={6} lg={8} md={8} sm={12} xs={12}>
                                        <Text className={'page-label'} type={'overline'} color={'black'}>
                                            {e.pageGroup}
                                        </Text>
                                        {e.pages.map((e: Page, i: number) => (
                                            <Row key={i} justify={'start'}>
                                                <Button
                                                    type={'link'}
                                                    data={{
                                                        actionType: 'redirect',
                                                        url: e.url
                                                    }}
                                                >
                                                    {e.title}
                                                </Button>
                                            </Row>
                                        ))}
                                    </Col>
                                ))}
                        </Row>
                    </Col>
                </Row>
                <Row className={'copyright-section'} align={'middle'} type={'flex'}>
                    <Text type={'overline'}>{this.props.copyright}</Text>
                </Row>
            </BackgroundColor>
        );
    }
}

export default function Footer(props: any) {
    const globalContext = useContext(GlobalContext);
    return (
        <FooterComponent {...{ ...props, ...globalContext }}></FooterComponent >
    )
}