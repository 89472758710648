import Text from '@component/Text';
import { ImageData, SlideInfo } from "@template/Carousel";
import React, { useContext } from 'react';
import "./index.scss";
import Button from '@component/Button';
import { ButtonData } from '@model/site';
import { GlobalContext } from 'context/GlobalContext';

type SlideWithCaptionPictureProps = {
  textData: SlideInfo['textData'];
  imageData: ImageData | null;
  buttonData?: ButtonData;
}

export const SlideWithCaptionPicture: React.FC<SlideWithCaptionPictureProps> = ({
  imageData,
  textData,
  buttonData
}: SlideWithCaptionPictureProps) => {
  const globalContext = useContext(GlobalContext);

  const getCaptionPicturePosition = (): ImageData['captionPicturePosition'] | '' => {
    return imageData?.captionPicturePosition ?? ''
  }

  const getImageSrc = (): string => {
    return imageData?.url ?? ''
  }

  const getImageFullSrc = (): string => {
    const imageFullSrc = `${globalContext.imageURL}${getImageSrc()}`
    return imageFullSrc
  }
  return (
      <div className={`slide-with-caption-picture ${getCaptionPicturePosition()}`}>
        <img className="slide-image" src={getImageFullSrc()} />
        <div className="text-section">
          <Text type={'headline'} color={'black'} className="slide-title">
              {textData?.title}
          </Text>
          <Text className="slide-content">{textData?.content}</Text>
          {
            buttonData != null 
            ?
              <Button
                className="slide-button"
                type={'outline'}
                size={'small'}
                data={buttonData}
              >
                {buttonData?.label}
              </Button>
            : undefined
          }
        </div>
      </div>
  )
}