import { ButtonData } from '@model/site';
import util from '@util/util';
import { Icon } from 'antd';
import React, { Component } from 'react';
import './index.scss';

type Props = {
    className?: string;
    type?: 'solid' | 'outline' | 'text' | 'link' | 'link-lg' | 'underline' | 'tag';
    size?: 'small' | 'normal' | 'large';
    children?: string;
    isDark?: boolean;
    disabled?: boolean;
    data?: ButtonData;
    loading?: boolean;
    onClick?: () => void;
    style?: React.CSSProperties;
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
    className: '',
    type: 'primary',
    size: 'normal',
    children: 'Know more',
    isDark: false,
    disabled: false,
    loading: false
};

type State = {};

export default class Button extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <button
                style={this.props.style}
                className={`button ${this.props.size} ${this.props.type} ${this.props.isDark ? 'dark' : ''} ${this.props.className}`}
                onClick={() => util.onClick(this.props.data, this.props.onClick)}
                disabled={this.props.disabled}
                onTouchEnd={() => {
                    const el: any = this;
                    const par = el.parentNode;
                    const next = el.nextSibling;
                    par.removeChild(el);
                    setTimeout(function () { par.insertBefore(el, next); }, 1)
                }}
            >
                {this.props.loading ? <Icon type="loading" style={{ fontSize: 24, color: 'white' }} spin /> : this.props.children}
                {this.props.type === 'text' && <Icon className={`icon ${this.props.isDark ? 'dark' : ''}`} type="arrow-right" />}
            </button>
        );
    }
}
