import Column from '@component-group/Column';
import BackgroundColor from '@component/BackgroundColor';
import Button from '@component/Button';
import Image from '@component/Image';
import Text from '@component/Text';
import Theme from '@constant/theme';
import { ButtonData, ColumnData } from '@model/site';
import { Col, Row } from 'antd';
import React, { Component, useContext } from 'react';
import './index.scss';
import { GlobalContext } from 'context/GlobalContext';

type Props = {
    backgroundColor?: 'grey' | 'white';
    type?: 'button' | 'column';
    position?: 'left' | 'right';
    title?: string;
    content?: string;
    buttonData?: ButtonData;
    columnData?: ColumnData[];
    imagePath?: string;
    imageURL: string;
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
    type: 'button',
    position: 'left',
    imagePath: 'img/img-payment.jpg',
    title: 'Add Section Title Here',
};

type State = {};

class PictureComponent extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        let columnLength = 1;
        if (this.props.columnData) {
            columnLength = this.props.columnData.length;
        }
        let columnSpan = 24 / columnLength;
        let pictureBody: JSX.Element[] = [
            <Col className={`picture-picture position-${this.props.position}`} key={0} xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                <Image className={`picture-image type-${this.props.type} position-${this.props.position}`} src={`${this.props.imageURL}${this.props.imagePath}`} />
            </Col>,
            <Col key={1} className={`picture-content position-${this.props.position}`} xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                <Text type={'header'} color={'black'}>
                    {this.props.title}
                </Text>
                <Text>{this.props.content}</Text>
                <Row className={`content-row type-${this.props.type}`} gutter={this.props.type == 'button' ? [0, 0] : Theme.GUTTER}>
                    {this.props.buttonData && this.props.type == 'button' ? (
                        <Button type={'outline'} data={this.props.buttonData}>
                            {this.props.buttonData?.label}
                        </Button>
                    ) : (
                            this.props.columnData?.map((column: ColumnData, i: number) => (
                                <Col key={i} xxl={columnSpan} xl={columnSpan} lg={columnSpan} md={columnSpan} sm={24} xs={24}>
                                    <Column
                                        className={'picture-column'}
                                        type={'image'}
                                        showBorder={false}
                                        align={'left'}
                                        buttonClassName={'picture-button'}
                                        data={column}
                                        imageURL={this.props.imageURL}
                                    />
                                </Col>
                            ))
                        )}
                </Row>
            </Col>,
        ];

        // if (this.props.position == 'right') {
        //     pictureBody = pictureBody.reverse();
        // }

        return (
            <BackgroundColor className={'picture-template'} color={this.props.backgroundColor}>
                <Row type={'flex'} align={'middle'} gutter={Theme.GUTTER}>
                    {pictureBody}
                </Row>
            </BackgroundColor>
        );
    }
}

export default function Picture(props: any) {
    const globalContext = useContext(GlobalContext);
    return (
        <PictureComponent {...{ ...props, ...globalContext }}></PictureComponent >
    )
}