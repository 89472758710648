import { all, call, fork, put, take } from 'redux-saga/effects';
import * as ActionTypes from './actions';
import * as api from './api';

function* apiEntity(entity: any, api: any, params: any) {
    //@ts-ignore
    const response = yield call(api, params);
    if (response && response.status === 200) {
        yield put(entity.success(response.data));
    } else {
        yield put(entity.failure(response.data));
    }
}

export const initialize = apiEntity.bind(null, ActionTypes.initialize, api.getSite);
export const loadPage = apiEntity.bind(null, ActionTypes.loadPage, api.getPage);

function* watchInialize() {
    while (true) {
        //@ts-ignore
        const params = yield take(ActionTypes.INITIALIZE.REQUEST);
        yield fork(initialize, params.url);
    }
}

function* watchLoadPage() {
    while (true) {
        //@ts-ignore
        const params = yield take(ActionTypes.LOAD_PAGE.REQUEST);
        yield fork(loadPage, params.url);
    }
}

export default function* root() {
    yield all([fork(watchInialize), fork(watchLoadPage)]);
}
