import React, { Component } from 'react';
import './index.scss';

type Props = {
    id?: string;
    className?: string;
    color?: 'grey' | 'white' | 'black' | 'blue';
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
    className: '',
    color: 'grey'
};

type State = {};

export default class BackgroundColor extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div id={this.props.id} className={`background-color ${this.props.color} ${this.props.className}`}>
                {this.props.children}
            </div>
        );
    }
}
