import React, { Component } from 'react';
import YouTube from 'react-youtube';
import './index.scss';

type Props = {
    className?: string;
    videoId?: string;
    autoplay?: 0 | 1;
    controls?: 0 | 1;
    loop?: 0 | 1;
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
    className: '',
    videoId: 'juN3u_Nb9ZQ',
    autoplay: 0,
    controls: 1,
    loop: 1
};

type State = {};

export default class VideoComponent extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className={`video ${this.props.className}`}>
                <YouTube
                    videoId={this.props.videoId}
                    opts={{
                        playerVars: {
                            autoplay: this.props.autoplay,
                            controls: this.props.controls,
                            loop: this.props.loop,
                            playsinline: 1,
                            origin: window.location.origin
                        }
                    }}
                />
            </div>
        );
    }
}
