import BackgroundImage, { Props as BackgroundImageProps } from '@component/BackgroundImage';
import Button from '@component/Button';
import Text from '@component/Text';
import { ButtonData } from '@model/site';
import * as api from '@redux/api';
import { ImageData, SlideInfo } from "@template/Carousel";
import history from '@util/history';
import util from '@util/util';
import { GlobalContext } from 'context/GlobalContext';
import React, { useContext } from 'react';
import "./index.scss";

type SlideWithBackgroundProps = {
  textData: SlideInfo['textData'];
  imageData: ImageData | null;
  buttonData?: ButtonData;
}

export const SlideWithBackground: React.FC<SlideWithBackgroundProps> = ({
  imageData,
  textData,
  buttonData,
}: SlideWithBackgroundProps) => {
  const globalContext = useContext(GlobalContext);

  const getImageSrc = (): string => {
    return imageData?.url ?? ''
  }

  const getImageFullSrc = (): string => {
    const imageFullSrc = `${globalContext.imageURL}${getImageSrc()}`
    return imageFullSrc
  }

  const handleBackgroundImageClick: BackgroundImageProps['onBackgroundImageClick'] = () => {
    if (imageData == null) {
      return
    }

    const actionTypeHandlerMap: Record<ImageData['actionType'], Function> = {
      none() {
        //do nothing
      },
      download() {
        api.download(imageData.actionUrl);
      },
      redirect() {
        if (util.validURL(imageData.actionUrl)) {
          window.location.href = imageData.actionUrl
          return
        }

        if (window.location.pathname != `/${imageData.actionUrl}`) {
          history.push(imageData.actionUrl);
          return
        }
      }
    }

    const handler = actionTypeHandlerMap[imageData.actionType]
    handler()
  }

  const getFloatingButtonContainerStyle = (): React.CSSProperties => {
    const style: React.CSSProperties = {
      alignItems: imageData?.floatingButtonData?.position.alignItems,
      justifyItems: imageData?.floatingButtonData?.position.justifyItems
    }
    return style
  }

  const getGridAreaStyle = (): React.CSSProperties => {
    const style: React.CSSProperties = {
      gridArea: imageData?.floatingButtonData?.position.gridArea
    }

    return style
  }

  return (
      <BackgroundImage 
        wrapperClassName={`slide-with-background ${imageData?.actionType ?? ''}`}
        backgroundPosition={imageData?.backgroundPosition}
        overlay={imageData?.backgroundOverlay}
        src={getImageFullSrc()}
        onBackgroundImageClick={handleBackgroundImageClick}
      >
        <div className="text-section">
            <Text type={"headline"} color={textData?.titleStyle?.color} align={textData?.titleStyle?.align}>
                {textData?.title ?? ''}
            </Text>
            <Text type={textData?.contentStyle?.type} color={textData?.contentStyle?.color} align={textData?.contentStyle?.align}>
                {textData?.content ?? ''}
            </Text>
            { buttonData != null ?
              <Button type={'outline'} isDark={true} data={buttonData}>
                {buttonData.label}
              </Button>
              : null
            }
        </div>
        <div className="floating-button-container" style={getFloatingButtonContainerStyle()}>
            {imageData?.floatingButtonData != null 
              ? (
                <Button 
                  type={'outline'} 
                  isDark={true}
                  size={'small'}
                  data={imageData?.floatingButtonData?.buttonData}
                  style={getGridAreaStyle()}
                >
                  {imageData?.floatingButtonData?.buttonData.label}
                </Button>
              )
              : null}
        </div>
      </BackgroundImage>
  )
}