const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

function createRequestTypes(base: string) {
    return [REQUEST, SUCCESS, FAILURE].reduce((acc: any, type) => {
        acc[type] = `${base}_${type}`;
        return acc;
    }, {});
}

export const INITIALIZE = createRequestTypes('INITIALIZE');
export const LOAD_PAGE = createRequestTypes('LOAD_PAGE');

function action(type: string, payload = {}) {
    return { type, ...payload };
}

export const initialize = {
    request: (url: string) => action(INITIALIZE[REQUEST], { url }),
    success: (response: { [key: string]: any }) => action(INITIALIZE[SUCCESS], { response }),
    failure: (error: { [key: string]: any }) => action(INITIALIZE[FAILURE], { error })
};

export const loadPage = {
    request: (url: string) => action(LOAD_PAGE[REQUEST], { url }),
    success: (response: { [key: string]: any }) => action(LOAD_PAGE[SUCCESS], { response }),
    failure: (error: { [key: string]: any }) => action(LOAD_PAGE[FAILURE], { error })
};

export const initializeRequest = (url: string) => action(INITIALIZE[REQUEST], { url });
export const loadPageRequest = (url: string) => action(LOAD_PAGE[REQUEST], { url });
