
import { Props as TextProps } from '@component/Text';
import { ButtonData } from '@model/site';
import { GlobalContext } from 'context/GlobalContext';
import { Property } from 'csstype';
import React, { Component, useContext } from 'react';
import { Autoplay, Navigation, Scrollbar } from 'swiper';
import 'swiper/modules/navigation/navigation.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import './index.scss';
import { SlideWithBackground } from '@component/SlideWIthBackground';
import { SlideWithCaptionPicture } from '@component/SlideWIthCaptionPicture';

export type ActionType = "redirect" | 'download' | "none";

export type ImageData = {
    type: 'background' | 'caption-picture';
    captionPicturePosition?: 'left' | 'right';
    backgroundPosition?: Property.BackgroundPosition;
    backgroundOverlay?: Property.BackgroundColor;
    url: string;
    actionType: ActionType;
    actionUrl: string;
    floatingButtonData?: FloatingButtonData;
}

export type ImageSizeData = Partial<Record<DeviceType, ImageData>>

export type TextData = {
    title?: string;
    titleStyle?: Pick<TextProps, 'align' | 'color' | 'type'>;
    content?: string;
    contentStyle?: Pick<TextProps, 'align' | 'color' | 'type'>;
}

export type FloatingButtonData = {
    buttonData: ButtonData;
    position: {
        gridArea: 
            "top-left" | "top" | "top-right" |
            "middle-left" | "center" | "middle-right" |
            "bottom-left" | "bottom" | "bottom-right";
        justifyItems: Property.JustifyItems;
        alignItems: Property.AlignItems;
    };
}

export type SlideInfo = {
    id: string;
    textData?: TextData;
    buttonData?: ButtonData;
    imageSizeData?: ImageSizeData;
};

type Props = {
    slideInfoList: SlideInfo[];
};

type State = {
    windowWidth: number;
};

export type DeviceType = 'mobile' | 'desktop'

class CarouselComponent extends Component<Props, State> {
    // static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth
        };
    }

    componentDidMount(): void {
        // Bind the method to the instance
        this.windowWidthListener = this.windowWidthListener.bind(this);
        // Add the event listener
        window.addEventListener("resize", this.windowWidthListener);
    }

    componentWillUnmount(): void {
        // Remove the event listener
        window.removeEventListener("resize", this.windowWidthListener);
    }

    windowWidthListener(): void {
        this.setState({ windowWidth: window.innerWidth });
    }

    getDeviceType(): DeviceType {
        if (this.state.windowWidth <= 768) {
            return 'mobile'
        }

        return 'desktop'
    }

    getImageData(imageSizeData: SlideInfo['imageSizeData']): ImageData | null {
        if (imageSizeData == null) return null
        
        const deviceType = this.getDeviceType()
        const imageData = imageSizeData[deviceType] ?? imageSizeData.desktop ?? imageSizeData.mobile ?? null
        return imageData
    }

    getSlideContent(slide: SlideInfo): React.ReactNode {
        const imageData = this.getImageData(slide.imageSizeData)
        
        if (imageData == null) {
            return undefined
        }

        if (imageData.type === 'background') {
            return (
                <SlideWithBackground
                    textData={slide.textData} 
                    imageData={imageData}
                    buttonData={slide.buttonData}
                />
            )
        }
        
        if (imageData.type === 'caption-picture') {
            return (
                <SlideWithCaptionPicture
                    textData={slide.textData} 
                    imageData={imageData}
                    buttonData={slide.buttonData}
                />
            )
        }

        return undefined
    }

    render() {
        return (
            <div className="carousel-template">
                <Swiper
                    modules={[Navigation, Scrollbar, Autoplay]}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation
                    scrollbar={{ draggable: true }}
                    loop
                    autoplay
                    >
                        {
                            this.props.slideInfoList.map((slide) => (
                                <SwiperSlide key={slide.id}>
                                    {this.getSlideContent(slide)}
                                </SwiperSlide>
                            ))
                        }
                </Swiper>
            </div>
           
        );
    }
}

export default function Carousel(props: any) {
    const globalContext = useContext(GlobalContext);
    return (
        <CarouselComponent {...{ ...props, ...globalContext }}></CarouselComponent >
    )
}