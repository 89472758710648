import React, { Component } from 'react';
import { Property } from 'csstype'
import './index.scss';

export type Props = {
    className?: string;
    wrapperClassName?: string;
    src?: string;
    overlay?: string;
    backgroundPosition?: Property.BackgroundPosition;
    onBackgroundImageClick?: React.MouseEventHandler<HTMLDivElement>;
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
    wrapperClassName: '',
    className: '',
    src: 'public/img/img-banner-c.jpg'
};

type State = {};

export default class BackgroundImage extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div 
                className={`background-image ${this.props.wrapperClassName}`} 
                style={{ 
                    backgroundImage: `url(${this.props.src})`,
                    backgroundPosition: this.props.backgroundPosition
                }}
                onClick={(e) => {this.props.onBackgroundImageClick?.(e)}}
            >
                <div className={`overlay ${this.props.className}`} style={{ background: this.props.overlay ?? '' }}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
