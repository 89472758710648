import Footer from '@component-group/Footer';
import NavigationBar from '@component-group/NavigationBar';
import { Page, SectionEntity, Site } from '@model/site';
import ErrorPage from '@page/ErrorPage';
import CardGroup from '@template/CardGroup';
import ColumnGroup from '@template/ColumnGroup';
import ContactForm from '@template/ContactForm';
import Header from '@template/Header';
import LogoWall from '@template/LogoWall';
import Map from '@template/Map';
import Picture from '@template/Picture';
import Quote from '@template/Quote';
import TextGroup from '@template/TextGroup';
import Video from '@template/Video';
import Posts from '@template/Posts';
import React, { Component } from 'react';
import Carousel from '@template/Carousel';
import './index.scss';

type Props = {
    site: Site;
    page: Page;
    errorCode?: 404 | 500;
}

const defaultProps = {};

type State = {};
export default class PageComponent extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps: Props) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        const { site, page } = this.props;
        if (this.props.errorCode) {
            return <ErrorPage errorCode={this.props.errorCode} />;
        }
        return (
            <div>
                <NavigationBar {...site} />
                <div className={'page-container'}>
                    <div>
                        {page.section?.map((e: SectionEntity, i: number) => {
                            switch (e.template) {
                                case 'Header':
                                    return <Header key={i} {...e.data} />;
                                case 'CardGroup':
                                    return <CardGroup key={i} {...e.data} />;
                                case 'ColumnGroup':
                                    return <ColumnGroup key={i} {...e.data} />;
                                case 'LogoWall':
                                    return <LogoWall key={i} {...e.data} />;
                                case 'Picture':
                                    return <Picture key={i} {...e.data} />;
                                case 'Map':
                                    return <Map key={i} {...e.data} />;
                                case 'Quote':
                                    return <Quote key={i} {...e.data} />;
                                case 'TextGroup':
                                    return <TextGroup key={i} {...e.data} />;
                                case 'ContactForm':
                                    return <ContactForm key={i} {...e.data} />;
                                case 'Video':
                                    return <Video key={i} {...e.data} />;
                                case 'Posts':
                                    return <Posts key={i} id={+e.sectionId} {...e.data} />;
                                case 'Carousel': // Rolling Banner
                                    return <Carousel key={i} {...e.data} />
                            }
                        })}
                    </div>
                    <Footer logo={site.footerLogo} social={site.social} pageGroups={site.pageGroups} copyright={site.copyright} />
                </div>
            </div>
        );
    }
}