import { Page, Site } from '@model/site';
import { AnyAction } from 'redux';
import * as ActionTypes from '../actions';

const initialState: { site?: Site; page?: Page } = {
    site: {
        headerUrl: '',
        countryUrl: '',
        pageGroups: [],
        navigationButtons: [],
        social: []
    },
    page: {
        url: '',
        id: '',
        title: ''
    }
};

function siteReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case ActionTypes.INITIALIZE.REQUEST:
            return {
                ...state
            };
        case ActionTypes.INITIALIZE.SUCCESS:
            return {
                ...state,
                site: action.response.site,
                page: action.response.page,
                errorCode: action.response.errorCode
            };
        case ActionTypes.INITIALIZE.FAILED:
            return {
                ...state
            };

        case ActionTypes.LOAD_PAGE.REQUEST:
            return {
                ...state
            };
        case ActionTypes.LOAD_PAGE.SUCCESS:
            return {
                ...state,
                page: action.response.page,
                errorCode: action.response.errorCode
            };
        case ActionTypes.LOAD_PAGE.FAILED:
            return {
                ...state
            };
        default:
            return state;
    }
}

export default siteReducer;
