import 'react-app-polyfill/ie9';
import store from '@redux/store';
import 'core-js/features/array';
import 'core-js/features/object';
import 'core-js/features/string';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './index.scss';
import { GlobalContextProvider } from 'context/GlobalContext';

render(
    <Provider store={store}>
        <GlobalContextProvider>
            <App />
        </GlobalContextProvider>
    </Provider >,
    document.getElementById('root')
);
