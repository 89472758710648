import React, { Component } from 'react';
import './index.scss';

export type Props = {
    className?: string;
    color?: 'grey' | 'white' | 'black' | 'blue' | 'slightTransparent';
    type?: 'headline' | 'header' | 'subhead' | 'title' | 'body' | 'caption' | 'overline';
    children?: string;
    align?: 'center';
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
    className: '',
    color: 'grey',
    type: 'body'
};

type State = {};

export default class Text extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        if (!this.props.children) return '';
        return (
            <div className={`text ${this.props.type} ${this.props.color} ${this.props.align ?? ''} ${this.props.className}`}>
                {this.props.children}
            </div>
        );
    }
}
