import axios from 'axios';

export async function getSite(url: string) {
    return axios.get(`${getBaseUrl()}/v1/site/${url}`);
}

export async function getPage(url: string) {
    return axios.get(`${getBaseUrl()}/v1/page/${url}`);
}

export async function sendInquiry(form: any) {
    return axios.post(`${getBaseUrl()}/v1/inquiry`, form);
}

export async function getPosts(id: number) {
    // return new Promise(resolve => setTimeout(() => resolve(), 1000)).then(() => { return axios.get(`${getBaseUrl()}/v1/post/${id}`); });
    return axios.get(`${getBaseUrl()}/v1/post/${id}`);
}

export async function download(file: string) {
    return window.open(`${getBaseUrl()}/v1/download/${file}`);
}

export function getBaseUrl() {
    switch (window.location.origin) {
        case 'http://localhost:3002':
            // return 'http://localhost:3001';
            return 'https://magicuat.ndhkcoupon.com';
        // return 'https://magicprod.ndhkcoupon.com';
        case 'http://ec2-18-163-56-216.ap-east-1.compute.amazonaws.com':
            return 'http://ec2-18-163-56-216.ap-east-1.compute.amazonaws.com:3001';
        case 'https://magicdev.ndhkcoupon.com':
            return 'https://magicdev.ndhkcoupon.com';
        case 'https://magicuat.ndhkcoupon.com':
            return 'https://magicuat.ndhkcoupon.com';
        case 'https://magicprod.ndhkcoupon.com':
            return 'https://magicprod.ndhkcoupon.com';
        case 'https://hk.nttdata.com':
            return 'https://hk.nttdata.com';
        default:
            return 'https://magicdev.ndhkcoupon.com';
    }
}
