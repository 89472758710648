import React, { useContext, useState, createContext } from 'react'
import util from '@util/util'

const initialValue = {
    imageURL:`https://magicsite.s3.ap-southeast-1.amazonaws.com/`+util.urlIsLocalhostOrUAT()
}

export const GlobalContext = createContext(initialValue)
export const GlobalContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [value, setValue] = useState(initialValue);

    return (
        <GlobalContext.Provider value={value}>
            {children}
        </GlobalContext.Provider>
    )
}
