import { ButtonData } from '@model/site';
import * as api from '@redux/api';
import history from '@util/history';
import ReactGA from 'react-ga';

export default {
    onClick(data?: ButtonData, onClick?: any) {
        ReactGA.event({
            category: 'Click',
            action: `Button-${data?.label}_${data?.id}`,
            label: data?.label
        });
        if (onClick != null) {
            onClick();
        } else if (data?.url) {
            if (data.actionType == 'redirect') {
                if (this.validURL(data.url)) {
                    window.location.href = data.url;
                } else if (window.location.pathname != `/${data.url}`) {
                    history.push(data.url);
                }
            }
            if (data.actionType == 'download') {
                api.download(data.url);
            }
        }
    },

    validURL(urlString: string): boolean {
        try {
            new URL(urlString);
        } catch (e) {
            return false;
        }
        return true;
    },

    urlIsLocalhostOrUAT(): string {
        const url = window.location.hostname;
        if (url.includes('uat')) {
            return 'uat/';
        }
        if (url.includes('dev') || url.includes('localhost')) {
            return 'dev/';
        }
        return 'prod/'
    }
};
