import Image from '@component/Image';
import React, { Component, useContext } from 'react';
import './index.scss';
import { Row, Col } from 'antd';
import Text from '@component/Text';
import { GlobalContext } from 'context/GlobalContext';

type Props = {
    errorCode: 404 | 500;
    imageURL: string;
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {};

type State = {};

class ErrorPageComponent extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Row className={'error-page-container'} type={'flex'} align={'middle'} justify={'center'}>
                <Col>
                    <Row type={'flex'} align={'middle'} justify={'center'}>
                        <Image className={'ntt-logo'} src={`${this.props.imageURL}img/logo-ntt-mark.svg`} />
                    </Row>
                    <Row type={'flex'} align={'middle'} justify={'center'}>
                        <Image
                            className={'error-img'}
                            src={this.props.errorCode == 404 ? `${this.props.imageURL}img/img-404.png` : `${this.props.imageURL}img/img-500.png`}
                        />
                    </Row>
                    <Text type={'header'} color={'black'} align={'center'}>
                        {this.props.errorCode == 404 ? 'Page Not Found' : 'Internal Server Error'}
                    </Text>
                    <Text align={'center'}>
                        {this.props.errorCode == 404
                            ? 'Sorry, we couldn’t find the page you are looking for…'
                            : 'Sorry for the inconvenience. We’re working on it…'}
                    </Text>
                </Col>
            </Row>
        );
    }
}

export default function ErrorPage(props: any) {
    const globalContext = useContext(GlobalContext);
    return (
        <ErrorPageComponent {...{ ...props, ...globalContext }}></ErrorPageComponent >
    )
}