import React, { Component } from 'react';
import './index.scss';

type Props = {
    id?: string;
    className?: string;
    src: string;
    onClick?: () => void;
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
    className: '',
    src: ''
};

type State = {};

export default class Image extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <img
                id={this.props.id}
                className={`image ${this.props.className}`}
                style={{ cursor: this.props.onClick ? 'pointer' : 'default', height: window.innerWidth < 410 ? 30 : undefined }}
                src={this.props.src}
                onClick={this.props.onClick}
            />
        );
    }
}
