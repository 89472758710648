import LogoList from '@component-group//LogoList';
import BackgroundColor from '@component/BackgroundColor';
import Button from '@component/Button';
import Text from '@component/Text';
import { ButtonData } from '@model/site';
import Row from 'antd/lib/grid/row';
import React, { Component, useContext } from 'react';
import './index.scss';
import { GlobalContext } from 'context/GlobalContext';

type Props = {
    backgroundColor?: 'grey' | 'white';
    title?: string;
    content?: string;
    buttonData?: ButtonData;
    logosPath?: string[];
    imageURL: string;
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {};
type State = {};

class LogoWallComponent extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <BackgroundColor className={'logo-wall-template'} color={this.props.backgroundColor}>
                <Text type={'header'} align={'center'} color={'black'}>
                    {this.props.title}
                </Text>
                <Text align={'center'}>{this.props.content}</Text>
                {this.props.buttonData && (
                    <Row align={'top'} justify={'center'} type={'flex'}>
                        <Button type={'outline'} data={this.props.buttonData}>
                            {this.props.buttonData.label}
                        </Button>
                    </Row>
                )}
                <LogoList logos={this.props.logosPath} imageURL={this.props.imageURL} />
            </BackgroundColor>
        );
    }
}

export default function LogoWall(props: any) {
    const globalContext = useContext(GlobalContext);
    return (
        <LogoWallComponent {...{ ...props, ...globalContext }}></LogoWallComponent >
    )
}