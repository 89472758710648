import BackgroundImage from '@component/BackgroundImage';
import Button from '@component/Button';
import Text from '@component/Text';
import { ButtonData } from '@model/site';
import { Col, Row } from 'antd';
import React, { Component, useContext } from 'react';
import './index.scss';
import { GlobalContext } from 'context/GlobalContext';

type Props = {
    type?: 'main' | 'page';
    backgroundPath?: string;
    title?: string;
    content?: string;
    buttonData?: ButtonData[];
    imageURL: string;
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
    type: 'page',
    backgroundPath: 'img/img-banner-c.jpg',
    title: 'One Global Payment Hub'
};

type State = {};

class HeaderComponent extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <BackgroundImage
                className={`header-template type-${this.props.type}`}
                src={`${this.props.imageURL}${this.props.backgroundPath}`}
                overlay={this.props.type == 'main' ? 'rgba(38, 54, 86, 0.6)' : undefined}
            >
                <Row type={'flex'} justify={'start'} align={'middle'}>
                    <Col span={24}>
                        <Text type={this.props.type == 'main' ? 'headline' : 'header'} color={'white'}>
                            {this.props.title}
                        </Text>
                        <Text color={'slightTransparent'}>{this.props.content}</Text>
                        {this.props.type == 'main' && this.props.buttonData && (
                            <Row className={'button-row'} type={'flex'} justify={'start'} gutter={[32, 16]}>
                                {this.props.buttonData.map((e: ButtonData, i: number) => (
                                    <Col key={i}>
                                        <Button type={'outline'} isDark={true} data={e}>
                                            {e.label}
                                        </Button>
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </Col>
                </Row>
            </BackgroundImage>
        );
    }
}

export default function Header(props: any) {
    const globalContext = useContext(GlobalContext);
    return (
        <HeaderComponent {...{ ...props, ...globalContext }}></HeaderComponent >
    )
}