import BackgroundColor from '@component/BackgroundColor';
import Button from '@component/Button';
import Image from '@component/Image';
import Text from '@component/Text';
import { ButtonData } from '@model/site';
import { Col, Row } from 'antd';
import React, { Component, useContext } from 'react';
import './index.scss';
import { GlobalContext } from 'context/GlobalContext';

type Props = {
    backgroundColor?: 'grey' | 'white';
    position?: 'left' | 'right';
    title?: string;
    content?: string;
    buttonData?: ButtonData;
    imageURL: string;
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
    position: 'left',
    title: 'Add Section Title Here',
    content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam.'
};

type State = {};

class MapComponent extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className={'map-template'}>
                <Row className={'full-page'} type={'flex'} justify={this.props.position == 'left' ? 'start' : 'end'}>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={16} xs={16}>
                        <Image className={`map-image ${this.props.position}`} src={`${this.props.imageURL}img/map.svg`} />
                    </Col>
                </Row>
                <BackgroundColor className={`map-content-background`} color={this.props.backgroundColor}>
                    <Row className={`map-content`} type={'flex'} align={'middle'} justify={this.props.position == 'left' ? 'end' : 'start'}>
                        <Col xxl={12} xl={12} lg={16} md={16} sm={24} xs={24}>
                            <Text type={'header'} color={'black'}>
                                {this.props.title}
                            </Text>
                            <Text>{this.props.content}</Text>
                            <Row className={'map-content-row'}>
                                <Button type={'outline'} data={this.props.buttonData}>
                                    {this.props.buttonData?.label}
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </BackgroundColor>
            </div>
        );
    }
}

export default function Map(props: any) {
    const globalContext = useContext(GlobalContext);
    return (
        <MapComponent {...{ ...props, ...globalContext }}></MapComponent >
    )
}