import Image from '@component/Image';
import Row from 'antd/lib/grid/row';
import React, { Component } from 'react';
import './index.scss';

type Props = {
    logos?: string[];
    imageURL: string;
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {};

type State = {
    windowWidth: number
};

export default class LogoList extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
        };
    }

    componentDidMount = () => {
        window.addEventListener("resize", () => {
            this.setState({ windowWidth: window.innerWidth })
        })
    }

    render() {
        return (
            <Row className={'logo-list-component'} align={'top'} justify={'center'} type={'flex'}>
                {this.props.logos?.map((logo, i) => {
                    return this.state.windowWidth < 410 ?
                        <div key={i} style={{ width: "50%", maxHeight: 50, textAlign: "center", marginBottom: 20 }}>
                            <Image key={i} className={'logo'} src={`${this.props.imageURL}${logo}`}></Image>
                        </div>
                        :
                        <Image key={i} className={'logo'} src={`${this.props.imageURL}${logo}`}></Image>
                })}
            </Row>
        );
    }
}
