import BackgroundImage from '@component/BackgroundImage';
import Button from '@component/Button';
import Text from '@component/Text';
import { Col, Row } from 'antd';
import React, { Component, useContext } from 'react';
import './index.scss';
import { ButtonData } from '@model/site';
import Image from '@component/Image';
import BackgroundColor from '@component/BackgroundColor';
import { GlobalContext } from 'context/GlobalContext';

type Props = {
    type?: 'light' | 'dark';
    backgroundPath?: string;
    backgroundColor?: 'grey' | 'white';
    title?: string;
    content?: string;
    buttonData?: ButtonData;
    imageURL: string;
} & Partial<DefaultProps>;

type DefaultProps = Readonly<typeof defaultProps>;

const defaultProps = {
    type: 'light',
    background: 'public/img/img-banner-b.jpg',
    title: 'One Global Payment Hub',
    content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam.'
};

type State = {};

class QuoteComponent extends Component<Props, State> {
    static defaultProps = defaultProps;
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        let quoteBody: JSX.Element = (
            <Row type={'flex'} justify={'start'} align={'middle'}>
                <Col className={'quote-content'}>
                    <Text type={'header'} color={this.props.type == 'dark' ? 'white' : 'black'}>
                        {this.props.title}
                    </Text>
                    <Text className={`content type-${this.props.type}`} color={this.props.type == 'dark' ? 'white' : 'grey'}>
                        {this.props.content}
                    </Text>
                    <Row className={'button-row'}>
                        {this.props.buttonData && (
                            <Button type={this.props.type == 'dark' ? 'outline' : 'solid'} isDark={true} data={this.props.buttonData}>
                                {this.props.buttonData.label}
                            </Button>
                        )}
                    </Row>
                </Col>
            </Row>
        );

        if (this.props.type == 'light') {
            quoteBody = (
                <BackgroundColor className={'quote-template'} color={this.props.backgroundColor}>
                    <Image className={'light-background'} src={`${this.props.imageURL}img/img-curve-ntt.png`} />
                    {quoteBody}
                </BackgroundColor>
            );
        }
        if (this.props.type == 'dark') {
            quoteBody = (
                <BackgroundImage className={'quote-template'} src={`${this.props.imageURL}${this.props.backgroundPath}`} overlay={'rgba(103, 133, 193, 0.4)'}>
                    {quoteBody}
                </BackgroundImage>
            );
        }
        return quoteBody;
    }
}

export default function Quote(props: any) {
    const globalContext = useContext(GlobalContext);
    return (
        <QuoteComponent {...{ ...props, ...globalContext }}></QuoteComponent >
    )
}